import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $data.AppLayout && $setup.routerComponents[$setup.routerComponents.length - 1] ? (_openBlock(), _createBlock(_resolveDynamicComponent($data.AppLayout), {
    key: 0,
    user: $data.user,
    routerComponent: $setup.routerComponents[$setup.routerComponents.length - 1],
    onSignOutClick: $options.userSignOut,
    version: $setup.version,
    logo: $setup.logo
  }, null, 40, ["user", "routerComponent", "onSignOutClick", "version", "logo"])) : _createCommentVNode("", true);
}